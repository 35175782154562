"use client";

/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-11-20 15:14:18
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-11-20 16:49:12
 * @FilePath: src/app/(main)/_public_components/client-offer-card.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import React from "react";
import OfferCard from "@/app/(main)/_public_components/offer-card";
import OfferCardInfo from "@/app/(main)/_public_components/offer-card-info";
import { OfferCardProps } from "@/TS/Interfaces/global_interfaces";
function ClientOfferCard(props: OfferCardProps) {
  function handleRender(props) {
    return <OfferCardInfo {...props} data-sentry-element="OfferCardInfo" data-sentry-component="handleRender" data-sentry-source-file="client-offer-card.tsx"></OfferCardInfo>;
  }
  return <OfferCard renderOfferInfo={handleRender} title={<span>
          Adhésion 1 an <br /> Membre Classique
        </span>} offerPrice={80} offerList={["Un engagement limité dans le temps", "Pas de limitation de membres sur cette formule"]} {...props} data-sentry-element="OfferCard" data-sentry-component="ClientOfferCard" data-sentry-source-file="client-offer-card.tsx"></OfferCard>;
}
export default ClientOfferCard;