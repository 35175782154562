import React from "react";
import { twMerge } from "tailwind-merge";
function SkewedBackground(props: {
  children: React.ReactNode;
  className?: string;
}) {
  return <span className={twMerge(`skewed-background ${props.className}`)} data-sentry-component="SkewedBackground" data-sentry-source-file="skewed-background.tsx">
      <span className={"z-10 relative"}>{props.children}</span>
    </span>;
}
export default SkewedBackground;