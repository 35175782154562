import(/* webpackMode: "eager" */ "/app/node_modules/@unleash/nextjs/dist/FlagProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@unleash/nextjs/dist/hooks.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/_public_components/block-form.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/_public_components/client-offer-card.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/_public_components/home-key-data.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/_public_components/home-map.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/_public_components/home-reason-card.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/_public_components/slider.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/_public_components/video-thumbnail.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/button.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/global/client-flash.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/global/mobile-nav.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/icon.tsx")