import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/src/app/(main)/(club)/mes-clubs/[id]/leads/context/dropdown-modal-provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/globals.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Sora\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"variable\":\"--font-sora\"}],\"variableName\":\"sora\"}");
import(/* webpackMode: "eager" */ "/app/src/components/LogRocketInitializer.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/right-panel.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/system/MaintenanceWatcher.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/breadcrumb-context.jsx");
import(/* webpackMode: "eager" */ "/app/src/context/callback-context-provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/club-map-context.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/club-provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/flash-context.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/is-burger-active.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/mobile-provider.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/Provider.js");
import(/* webpackMode: "eager" */ "/app/src/context/sidebar-context.tsx");
import(/* webpackMode: "eager" */ "/app/src/providers/tanstack/QueryProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/utils/close-mobile-nav-when-path-change.tsx")