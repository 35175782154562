/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-10-08 12:01:03
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-12-10 11:04:45
 * @FilePath: src/app/(main)/_public_components/map-cookie-fallback.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */

import React from "react";
import { twMerge } from "tailwind-merge";
function MapCookieFallback({
  className
}: {
  className?: string;
}) {
  // 🧛🏻‍♀️ Make the tarteaucitron modal appear
  const handleClick = () => {
    const citronModal = document.getElementById("tarteaucitron");
    if (!citronModal) return;
    const tarteaucitronBack = document.getElementById("tarteaucitronBack");
    if (!tarteaucitronBack) return;
    citronModal.style.opacity = "1";
    citronModal.style.display = "block";
    tarteaucitronBack.style.opacity = ".7";
    tarteaucitronBack.style.display = "block";
  };
  // 🧛🏻‍♀️

  return <div className={twMerge("absolute text-center text-sm md:text-lg flex gap-6 flex-col items-center justify-center inset-0 bg-light-grey z-10  md:mx-8 md:mb-8", className)} data-sentry-component="MapCookieFallback" data-sentry-source-file="map-cookie-fallback.tsx">
      <p>
        Vous avez refusé les cookies, par conséquent la carte localisant <br />{" "}
        les Clubs d'Affaires Dynabuy ne s'affiche pas.
      </p>
      <p>
        Pour afficher la carte,{" "}
        <b onClick={handleClick} className={"underline cursor-pointer"}>
          merci d'accepter les cookies
        </b>
      </p>
    </div>;
}
export default MapCookieFallback;