"use client";

/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-11-20 14:18:41
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-11-20 17:04:02
 * @FilePath: src/app/(main)/_public_components/offer-card-info.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { OfferCardInfoProps } from "@/TS/Interfaces/global_interfaces";
function OfferCardInfo({
  optionsList,
  defaultSelected = 0,
  setSelectedPrice,
  ...rest
}: OfferCardInfoProps) {
  const [activedToggle, setActivedToggle] = useState<number>(defaultSelected);
  const handleClick = (index, value) => {
    setActivedToggle(index);
    setSelectedPrice(value);
  };
  return <div {...rest} className={"bg-secondary w-fit rounded-md flex gap-2 mb-3 text-sm p-1"} data-sentry-component="OfferCardInfo" data-sentry-source-file="offer-card-info.tsx">
      {optionsList.map(({
      label,
      value
    }, index) => {
      return <p key={label} onClick={() => handleClick(index, value)} className={twMerge("rounded-md transition-colors cursor-pointer flex justify-center items-center px-2 py-1.5 text-sm hover:bg-white hover:text-secondary text-center", activedToggle === index ? "bg-white text-secondary" : "")}>
            {label}
          </p>;
    })}
    </div>;
}
export default OfferCardInfo;