export enum EventEnum {
  MEETING = "meeting",
  INTERVIEW = "interview",
  EVENT = "event",
}
export enum EventTransitionEnum {
  CANCEL = "cancel",
  RESTORE = "restore",
}
export enum LeadStatusEnum {
  RECEIVED = "received",
  ACCEPTED = "accepted",
  SENT = "sent",
  TRANSFORMED = "transformed",
  NOT_TRANSFORMED = "not_transformed",
  REFUSED = "refused",
  CANCELLED = "cancelled",
}

export enum RoleEnum {
  PRESIDENT = "Président",
  MEMBER = "Membre",
}

export enum LeadTransitionEnum {
  ACCEPT = "accept",
  REFUSE = "refuse",
  CANCEL = "cancel",
  TRANSFORM = "transform",
  NOT_TRANSFORM = "not_transform",
  MODIFY = "modify",
}
export enum RegistrationTabEnum {
  WAITING = "waiting",
  HISTORY = "history",
}
export enum FiltersEnum {
  ONLY = "only",
  NOT = "not",
}
// TODO mettre les bons filtres
export enum AdhesionEnum {
  ACCEPTED = "president_accepted",
  REFUSED = "president_refused",
  WAIT = "pending_sign",
  SIGNED = "signed",
}
export enum HydraEnum {
  LIST = "hydra:member",
  ITEMS_COUNT = "hydra:totalItems",
}

export enum ProfileInformationsEnum {
  ACTIVITY = "Activité",
  COMPANY_NAME = "Nom de l'entreprise",
  WEBSITE = "Site web",
}
export enum userProfileListEnum {
  ACTIVITY = "Activité",
  SIRET = "N° SIRET",
  COMPANY_NAME = "Nom de l'entreprise",
  CAPITAL = "Capital",
  WEBSITE = "Site web",
  JOB_TITLE = "Fonction",
  POSTAL_ADRESS = "Adresse",
}
export enum InvitationTransitionEnum {
  PRESIDENT_ACCEPT = "president_accept",
  PRESIDENT_REFUSE = "president_refuse",
  GUEST_ACCEPT = "guest_accept",
  GUEST_REFUSE = "guest_refuse",
  REVALIDATE = "revalidate",
}

export enum ContractClubMemberPaymentEnum {
  YEARLY = "yearly",
  MONTHLY = "monthly",
  CASH = "cash",
  DEFAULT_YEARLY_AMOUNT = 800,
  DEFAULT_MONTHLY_AMOUNT = 80,
  DEFAULT_INFINITE_AMOUNT = 1600,
  CASH_COUNT = 1,
  YEARLY_COUNT = 1,
  MONTHLY_COUNT = 12,
}

export enum ClubTypeEnum {
  CLUB = "club",
  REGISTRATION = "registration",
  PARTICIPATION = "participation_request",
  INVITATION = "invitation_request",
}
export enum ClubStatusEnum {
  PENDING = "pending",
  ACCEPTED = "accepted",
  COMPLETED = "completed",
  PRESIDENT_REFUSED = "president_refused",
  ACTIVE = "active",
}
export enum ClubRoleEnum {
  PRESIDENT = "President",
  MEMBER = "Member",
  NOT_MEMBER = "Not member",
}

export enum FooterItemsLabelsEnum {
  CLUBS_CONCEPTS = "Concept des clubs",
  JOIN_CLUB = "Rejoindre un club",
  CREATE_CLUB = "Créer un club",
  DYNABUY_NETWORK = "Dynabuy",
  TESTIMONIALS = "Témoignages",
  CONNEXION = "Connexion",
  CGU = "Condition Générales d'Utilisation",
  HOME = "Accueil",
  CONTACT = "Contact",
  LEGAL_NOTICES = "Mentions légales",
  PRIVACY_AND_COOKIES_POLICY = "Politique de confidentialité et de cookies",
}
export enum GlobalRouteEnums {
  CLUBS_CONCEPTS = "/concept-des-clubs",
  JOIN_CLUB = "/rejoindre-un-club",
  CREATE_CLUB = "/creer-un-club",
  DYNABUY_NETWORK = "/dynabuy",
  TESTIMONIALS = "/temoignages",
  CONNEXION = "/connexion",
  CGU = "/conditions-generales-dutilisation",
  HOME = "/",
  LEGAL_NOTICES = "/mentions-legales",
  PRIVACY_AND_COOKIES_POLICY = "/pdf/politique-confidentialite.pdf",
}
